'use strict';

(function() {

/**
 * The Util service is for thin, globally reusable, utility functions
 */
function UtilService($window) {
  var Util = {
    /**
     * Return a callback or noop function
     *
     * @param  {Function|*} cb - a 'potential' function
     * @return {Function}
     */
    safeCb(cb) {
      return (angular.isFunction(cb)) ? cb : angular.noop;
    },

    /**
     * Parse a given url with the use of an anchor element
     *
     * @param  {String} url - the url to parse
     * @return {Object}     - the parsed url, anchor element
     */
    urlParse(url) {
      var a = document.createElement('a');
      a.href = url;

      // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
      if (a.host === '') {
        a.href = a.href;
      }

      return a;
    },

    /**
     * Test whether or not a given url is same origin
     *
     * @param  {String}           url       - url to test
     * @param  {String|String[]}  [origins] - additional origins to test against
     * @return {Boolean}                    - true if url is same origin
     */
    isSameOrigin(url, origins) {
      url = Util.urlParse(url);
      origins = (origins && [].concat(origins)) || [];
      origins = origins.map(Util.urlParse);
      origins.push($window.location);
      origins = origins.filter(function(o) {
        return url.hostname === o.hostname &&
          url.port === o.port &&
          url.protocol === o.protocol;
      });
      return (origins.length >= 1);
    },

    /**
     * Determine if a date is between tow dates
     *
     * @param  {Date}             date      - Date to test
     * @param  {Date}             startDate - Start date of periode
     * @param  {Date}             endDate   - End date of periode
     * @return {Boolean}                    - True if is between, false otherwise
     */
    isDateInTimePeriod(date, startDate, endDate) {
      return date > startDate && date < endDate;
    },

    /**
     * Returns an array of date for a week arround the date in param
     *
     * @param  {Date}             date      - date arround which to get the week
     * @return {Date[]}                     - array of date for all week
     */
    getWeekArray(date) {
      var monday = new Date(date);
      while (monday.getDay() !== 1) {
        monday.setDate(monday.getDate() - 1);
      }

      var dateArray = [];
      for (var i=0 ; i<7 ; i++) {
        var newDate = new Date(monday);
        newDate.setDate(monday.getDate() + i);
        dateArray.push(newDate);
      }

      return dateArray;
    },

    /**
     * Returns true if date is week day
     *
     * @param  {Date}             date      - date
     * @return {Bool}                       - bool if date i weekday
     */
    isWeekDay(date) {
      return date.getDay() > 0 && date.getDay() < 6;
    },

    /**
     * Creates a title to describe
     *
     * @param  {Date}             startDate - start of week
     * @param  {Date}             endDate   - end of week
     * @return {String}                     - title to describe week
     */
    getWeekTitle(startDate, endDate) {
      if (startDate.getMonth() === endDate.getMonth()) {
        return "Semaine du " + startDate.getDate() + " au " + endDate.getDate() + " " + Util.getShortMonthString(startDate) + " " + endDate.getFullYear()
      } else {
        return "Semaine du " + startDate.getDate() + " " + Util.getShortMonthString(startDate) + " au " + endDate.getDate() + " " + Util.getShortMonthString(endDate) + " " + endDate.getFullYear()
      }
    },

    /**
     * Returns a short string representing the day of the date
     *
     * @param  {Date}             date      - desired date
     * @return {String}                     - short string representing the day
     */
    getShortDayString(date) {
      switch(date.getDay()) {
        case 1:
          return "Lun";
        case 2:
          return "Mar";
        case 3:
          return "Mer";
        case 4:
          return "Jeu";
        case 5:
          return "Ven";
        case 6:
          return "Sam";
        case 0:
          return "Dim";
        default:
          return '';
      }
    },

    /**
     * Returns a short string representing the month of the date
     *
     * @param  {Date}             date      - desired date
     * @return {String}                     - short string representing the month
     */
    getShortMonthString(date) {
      switch(date.getMonth()) {
        case 0:
          return "Jan";
        case 1:
          return "Fev";
        case 2:
          return "Mar";
        case 3:
          return "Avr";
        case 4:
          return "Mai";
        case 5:
          return "Juin";
        case 6:
          return "Juil";
        case 7:
          return "Aout";
        case 8:
          return "Sep";
        case 9:
          return "Oct";
        case 10:
          return "Nov";
        case 11:
          return "Dec";
        default:
          return '';
      }
    },

    /**
     * Returns a short string representing the time of the date
     *
     * @param  {Date}             date      - desired date
     * @return {String}                     - short string representing the time
     */
    getTimeString(date) {
      const a = date.getHours() > 11 ? 'pm' : 'am';
      var hour = date.getHours() === 0 ? 12 : date.getHours();
      hour = hour > 12 ? hour - 12 : hour;
      const minutesString = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      return hour + 'h' + minutesString + ' ' + a;
    },

    /**
     * Returns a string representing the date
     *
     * @param  {Date}             date      - desired date
     * @return {String}                     - string representing the date
     */
    getDateString(date, withYear = false) {
      if(!date) return  "N.D";
      const year = withYear ? ' ' + date.getFullYear()  : '';
      return Util.getShortDayString(date) + ' ' + date.getDate() + ' ' + Util.getShortMonthString(date) + year;
    },

    /**
     * Returns a string representing the date
     *
     * @param  {Date}             date      - desired date
     * @param  {Boolean}          withYear  - adds the year in the returned string
     * @return {String}                     - string representing the date
     */
    getDateTimeString(date, withYear = false) {
      const year = withYear ? date.getFullYear() + ' ' : '';
      return Util.getShortDayString(date) + ' ' + date.getDate() + ' ' + Util.getShortMonthString(date) + ' ' + year + Util.getTimeString(date);
    },

    /**
     * Returns a string representing the number with thousands seperated by commas
     *
     * @param  {int}             number     - desired number
     * @return {String}                     - string representing the number
     */
    formatThousands(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

  };

  return Util;
}

angular.module('cpformplastApp.util')
  .factory('Util', UtilService);

})();
